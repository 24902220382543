html {
  overflow-y: scroll;
  margin-right: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f5f5f5;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

.react-confirm-alert-button-group button {
  background: #00509b !important;
}

.react-confirm-alert-body {
  font-family: QuicksandMedium !important;
  font-size: 18px;
  text-align: center;
}

.react-confirm-alert-body button {
  font-family: QuicksandMedium;
}

.react-confirm-alert-body h1 {
  font-family: QuicksandMedium;
  font-size: 18px;
  text-align: center;
}

.confirm-alert {
  padding: 30px;
  background-color: white;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
}

.confirm-alert h1 {
  font-family: QuicksandMedium;
  font-size: 18px;
  text-align: center;
}

.confirm-alert p {
  font-family: QuicksandMedium;
  width: 400px;
  padding: 30px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
}

.alert-btn {
  font-family: QuicksandMedium;
  background-color: #00509b;
  color: white;
  margin-left: 60px
}
